// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import i18n from '../../i18n';
import * as constants from '../../services/constants';

import './styles.css';

const supportUrl = constants.SUPPORT_URL;

type Props = {
  t: string => string,
  className: string,
};

const Footer = ({ t, className }: Props) => {
  return (
    <footer className={`footer ${className}`}>
      <span className="footer__copyright">{t('home.footer.copyright')}</span>
      <nav className="footer__navigation">
        <ul className="footer__navigation-list">
          <li className="footer__navigation-list-item">
            <Link ta-id="footer_cookies" to="/cookies">
              {t('home.footer.cookies')}
            </Link>
          </li>
          <li className="footer__navigation-list-item">
            <Link ta-id="footer_terms" to="/terms-of-use">
              {t('home.footer.terms')}
            </Link>
          </li>
          <li className="footer__navigation-list-item">
            <Link ta-id="footer_privacy" to="/privacy">
              {t('home.footer.privacy')}
            </Link>
          </li>
          <li className="footer__navigation-list-item">
            <a
              href={`${supportUrl}${
                i18n.language === 'nl-NL' ? 'nl' : 'en'
              }/support/home`}
              ta-id="footer_feedback"
            >
              {t('home.footer.feedback')}
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default translate()(Footer);
