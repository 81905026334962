import * as eventsService from '../services';
import { beginCall, errorCall } from '../../../actions/calls';
export function eventsFetchInProgress() {
  return {
    type: 'EVENTS_FETCH_PROGRESS',
  };
}

export function typesSuccess(types) {
  return {
    type: 'OCCASION_TYPES_SUCCESS',
    types,
  };
}

export function typesFail(types) {
  return {
    type: 'OCCASION_TYPES_FAIL',
    types,
  };
}

export function createSuccess(event) {
  return {
    type: 'CREATE_EVENT_SUCCESS',
    event,
  };
}

export function createFail() {
  return {
    type: 'CREATE_EVENT_FAIL',
  };
}

export function editSuccess(event) {
  return {
    type: 'EDIT_EVENT_SUCCESS',
    event,
  };
}

export function editFail() {
  return {
    type: 'EDIT_EVENT_FAIL',
  };
}

export function deleteSuccess(id) {
  return {
    type: 'DELETE_EVENT_SUCCESS',
    id,
  };
}

export function deleteFail() {
  return {
    type: 'DELETE_EVENT_FAIL',
  };
}

export function eventsSuccess(events) {
  return {
    type: 'EVENTS_LIST_SUCCESS',
    events,
    pageSize: eventsService.pageSize,
  };
}

export function clearList() {
  return {
    type: 'CLEAR_EVENTS_LIST',
  };
}

export function eventsFail() {
  return {
    type: 'EVENTS_LIST_FAIL',
  };
}

export function eventSuccess(event) {
  return {
    type: 'EVENT_SUCCESS',
    event,
  };
}

export function eventReset() {
  return {
    type: 'EVENT_RESET',
  };
}

export function eventDetailsSuccess(eventDetails) {
  return {
    type: 'EVENT_DETAILS_SUCCESS',
    eventDetails,
  };
}

export function eventFail() {
  return {
    type: 'EVENT_FAIL',
  };
}

export function MessageSuccess(message) {
  return {
    type: 'MESSAGE_SUCCESS',
    message,
  };
}

export function MessageFail(message) {
  return {
    type: 'MESSAGE_FAIL',
  };
}

export function fetchMessagesSuccess(messages) {
  return {
    type: 'MESSAGES_SUCCESS',
    messages,
  };
}

export function pollerMessagesSuccess(messages) {
  return {
    type: 'POLLER_MESSAGES_SUCCESS',
    messages,
  };
}
export function resetMessagesSuccess(messages) {
  return {
    type: 'MESSAGES_RESET',
    messages,
  };
}

export function fetchMessagesTotal(total) {
  return {
    type: 'MESSAGES_TOTAL',
    total,
  };
}

export function decreaseMessagesTotal() {
  return {
    type: 'MESSAGES_DECREASE',
  };
}

export function fetchTotalPages(total) {
  return {
    type: 'TOTAL_PAGES_SUCCESS',
    total,
  };
}

export function resetLastCommentPage() {
  return {
    type: 'MESSAGES_LAST_FALSE',
  };
}

export function fetchMessagesFail() {
  return {
    type: 'MESSAGES_FAIL',
  };
}

export function editCommentSuccess(id, message) {
  return {
    type: 'EDIT_COMMENT_SUCCESS',
    id,
    message,
  };
}

export function deleteCommentSuccess(id) {
  return {
    type: 'DELETE_COMMENT_SUCCESS',
    id,
  };
}

export function fetchInviteesSuccess(invitees) {
  return {
    type: 'INVITEES_SUCCESS',
    invitees,
  };
}

export function fetchInviteesFail() {
  return {
    type: 'CONTRIBUTORS_FAIL',
  };
}

export function fetchParticipantsSuccess(participants) {
  return {
    type: 'PARTICIPANTS_SUCCESS',
    participants,
  };
}

export function fetchParticipantsFail() {
  return {
    type: 'PARTICIPANTS_FAIL',
  };
}

export function fetchPendingInviteesSuccess(pendingInvitees) {
  return {
    type: 'PENDING_INVITEES_SUCCESS',
    pendingInvitees,
  };
}

export function fetchPendingInviteesFail() {
  return {
    type: 'PENDING_INVITEES_FAIL',
  };
}

export function fetchContributorsSuccess(contributors) {
  return {
    type: 'CONTRIBUTORS_SUCCESS',
    contributors,
  };
}

export function fetchContributorsFail() {
  return {
    type: 'INVITEES_FAIL',
  };
}

export function fetchCreditCardDataSucess(card) {
  return {
    type: 'CREDIT_CARD_FETCH_SUCCESS',
    card,
  };
}
export function fetchCreditCardDataFail() {
  return {
    type: 'CREDIT_CARD_FAIL',
  };
}

export function deleteCreditCardSuccess() {
  return {
    type: 'DELETE_CREDIT_CARD_SUCCESS',
  };
}
export function deleteCreditCardFail() {
  return {
    type: 'DELETE_CREDIT_CARD_FAIL',
  };
}

export function fetchDebitCardDataSucess(card) {
  return {
    type: 'DEBIT_CARD_FETCH_SUCCESS',
    card,
  };
}
export function fetchDebitCardDataFail() {
  return {
    type: 'DEBIT_CARD_FAIL',
  };
}

export function deleteDebitCardSuccess() {
  return {
    type: 'DELETE_DEBIT_CARD_SUCCESS',
  };
}
export function deleteDebitCardFail() {
  return {
    type: 'DELETE_DEBIT_CARD_FAIL',
  };
}

export function profileDataNext(profileData) {
  return {
    type: 'PROFILE_DATA_NEXT',
    profileData,
  };
}

export function cardDataNext(cardData) {
  return {
    type: 'CARD_DATA_NEXT',
    cardData,
  };
}

export function profileDataNextClear() {
  return {
    type: 'PROFILE_DATA_NEXT_CLEAR',
  };
}

export function cardDataNextClear() {
  return {
    type: 'CARD_DATA_NEXT_CLEAR',
  };
}

export function clearCardData() {
  return {
    type: 'CARD_DATA_CLEAR',
  };
}

export function hideForm() {
  return {
    type: 'TOGGLE_PROFILE_FORM',
    show: false,
  };
}

export function showForm() {
  return {
    type: 'TOGGLE_PROFILE_FORM',
    show: true,
  };
}

export function requestedWLSuccess(invitation) {
  return {
    type: 'EVENT_REQUESTED_WISHLIST_SUCCESS',
    invitation,
  };
}

export function eventWLGoalsSuccess(goals) {
  return {
    type: 'EVENT_WISHLIST_GOALS',
    goals,
  };
}

export function joinEventSuccess() {
  return {
    type: 'JOIN_EVENT_SUCCESS',
  };
}

export function joinEventCheck(eventId) {
  return {
    type: 'JOIN_EVENT_CHECK',
    eventId,
  };
}

export function requestWishlistStatus(status) {
  return {
    type: 'WISHLIST_REQUEST_STATUS',
    status,
  };
}

export function cashOutInvitation(eventUuid, invitationUuid) {
  return {
    type: 'NOTIF_EVENT_CASH_OUT',
    eventUuid,
    invitationUuid,
  };
}

const clearEventsListAndDetails = dispatch => {
  dispatch(eventReset());
  dispatch(clearList());
};

export function fetchOccasionTypes() {
  return dispatch => {
    return eventsService
      .getOccasionTypes()
      .then(response => {
        dispatch(typesSuccess(response.data));
      })
      .catch(() => {
        dispatch(eventsFail());
      });
  };
}

export function createEvent(event) {
  return dispatch => {
    dispatch(beginCall());
    return new Promise((resolve, reject) => {
      eventsService
        .createEvent(eventsService.getNewEvent(event))
        .then(response => {
          let image;
          if (window.localStorage.getItem('listiEventImage') !== null) {
            const localImage = JSON.parse(
              window.localStorage.getItem('listiEventImage')
            );
            const file = eventsService.dataURLtoFile(localImage);

            image = file;
          } else {
            if (event.iconUrl) {
              image = event.iconUrl[0];
            }
          }

          if (image) {
            eventsService
              .uploadImage(image, response.data.id)
              .then(() => {
                resolve();
                dispatch(createSuccess(response.data));
                dispatch(clearList());
                if (window.localStorage.getItem('listiEventImage') !== null) {
                  localStorage.removeItem('listiEventImage');
                }
              })
              .catch(reject);
          } else {
            resolve();
            dispatch(createSuccess(response.data));
            dispatch(clearList());
          }
        })
        .catch(() => {
          reject();
          dispatch(typesFail());
        });
    });
  };
}

export function editEvent(event) {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .editEvent(eventsService.getNewEvent(event))
      .then(response => {
        if (event.iconUrl instanceof FileList) {
          eventsService.uploadImage(event.iconUrl[0], response.data.id);
        }
        dispatch(editSuccess(response.data));
      })
      .catch(() => {
        dispatch(editFail());
      });
  };
}

export function deleteEvent(id) {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .deleteEvent(id)
      .then(response => {
        dispatch(deleteSuccess(id));
      })
      .catch(() => {
        dispatch(deleteFail());
      });
  };
}

export function submitComment(comment, eventId) {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .postUserComment(comment, eventId)
      .then(response => {})
      .catch(() => {
        dispatch(errorCall());
      });
  };
}

export function editComment(comment, eventId, commentId) {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .editUserComment(comment, eventId, commentId)
      .then(response => {
        dispatch(
          editCommentSuccess(
            response.data.id,
            eventsService.highlightLinks(response.data.messageBody)
          )
        );
      })
      .catch(() => {
        dispatch(errorCall());
      });
  };
}

export function deleteComment(eventId, commentId, pageIndex) {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .deleteUserComment(eventId, commentId)
      .then(response => {
        return eventsService.getMessages(eventId, pageIndex).then(response => {
          dispatch(deleteCommentSuccess(commentId));
          dispatch(fetchMessagesSuccess(response.data.content));
          dispatch(fetchMessagesTotal(response.data.totalElements));
          dispatch(fetchTotalPages(response.data.totalPages));
        });
      })
      .catch(() => {
        dispatch(errorCall());
      });
  };
}

export function fetchMessages(eventId, pageIndex) {
  return dispatch => {
    return eventsService
      .getMessages(eventId, pageIndex)
      .then(res => {
        dispatch(fetchMessagesSuccess(res.data.content));
        dispatch(fetchMessagesTotal(res.data.totalElements));
        dispatch(fetchTotalPages(res.data.totalPages));
      })
      .catch(() => {
        dispatch(fetchMessagesFail());
      });
  };
}

export function pollerMessages(eventId) {
  return dispatch => {
    return eventsService
      .getMessages(eventId, 1) //always latest messages, default newest
      .then(res => {
        dispatch(pollerMessagesSuccess(res.data.content));
        dispatch(fetchMessagesTotal(res.data.totalElements));
        dispatch(fetchTotalPages(res.data.totalPages));
      })
      .catch(() => {
        dispatch(fetchMessagesFail());
      });
  };
}

export function resetMessages(eventId, pageIndex) {
  return dispatch => {
    return eventsService
      .getMessages(eventId, pageIndex)
      .then(res => {
        dispatch(resetMessagesSuccess(res.data.content));
        dispatch(fetchMessagesTotal(res.data.totalElements));
        dispatch(fetchTotalPages(res.data.totalPages));
      })
      .catch(() => {
        dispatch(fetchMessagesFail());
      });
  };
}

export function fetchInvitees(eventId) {
  return dispatch => {
    return eventsService
      .getInvitees(eventId)
      .then(res => {
        dispatch(fetchInviteesSuccess(res.data));
      })
      .catch(() => {
        dispatch(fetchInviteesFail());
      });
  };
}

export function fetchParticipants(eventId) {
  return dispatch => {
    return eventsService
      .getParticipants(eventId)
      .then(res => {
        dispatch(fetchParticipantsSuccess(res.data));
      })
      .catch(() => {
        dispatch(fetchParticipantsFail());
      });
  };
}

export function fetchPendingInvitees(eventId) {
  return dispatch => {
    return eventsService
      .getPendingInvitees(eventId)
      .then(res => {
        dispatch(fetchPendingInviteesSuccess(res.data));
      })
      .catch(() => {
        dispatch(fetchPendingInviteesFail());
      });
  };
}

export function fetchContributors(eventId) {
  return dispatch => {
    return eventsService
      .getContributors(eventId)
      .then(res => {
        dispatch(fetchContributorsSuccess(res.data));
      })
      .catch(() => {
        dispatch(fetchContributorsFail());
      });
  };
}

export function fetchUserEvents(page, filter, sorting) {
  return dispatch => {
    dispatch(eventsFetchInProgress());
    return eventsService
      .getUserEvents(page, filter, sorting)
      .then(response => {
        dispatch(eventsSuccess(response.data));
      })
      .catch(() => {
        dispatch(eventsFail());
      });
  };
}

export function fetchEventById(id) {
  return dispatch => {
    return eventsService
      .getEventById(id)
      .then(response => {
        dispatch(eventSuccess(response.data));
      })
      .catch(() => {
        dispatch(eventFail());
      });
  };
}

export function fetchEventDetails(id) {
  return dispatch => {
    return eventsService
      .getEventDetails(id)
      .then(response => {
        dispatch(eventDetailsSuccess(response.data));
      })
      .catch(() => {
        dispatch(eventFail());
      });
  };
}

export function fetchPublicEventByUuid(uuid) {
  return dispatch => {
    return eventsService
      .getPublicEventByUuid(uuid)
      .then(response => {
        dispatch(eventSuccess(response.data));
      })
      .catch(e => {
        dispatch(eventFail());
        throw e;
      });
  };
}

export function idealContributeWithAccount(data, id, currency) {
  return dispatch => {
    return eventsService
      .idealContribute(eventsService.getIdealPaymentData(data, currency), id)
      .then(response => {
        // TODO need cleanup
        const amount = window.localStorage.getItem('amountToContribute');
        const ticketType = window.localStorage.getItem('ticketType');
        if (ticketType && ticketType !== 'undefined') {
          window.localStorage.removeItem('ticketType');
        }
        if (amount && amount !== 'undefined') {
          window.localStorage.removeItem('amountToContribute');
          // store contributedToUdenEvent for redirect after payment-return
          window.localStorage.setItem(
            'contributedToUdenEvent',
            JSON.stringify(1)
          );
        }
        dispatch({ type: 'IDEAL_CONTRIBUTE_WITH_ACCOUNT_SUCCESS' });
        return response.data;
      });
  };
}

export function idealContributeWithoutAccount(data, id, currency) {
  return dispatch => {
    return eventsService
      .idealContributeWithoutAccount(
        eventsService.getIdealPaymentData(data, currency),
        id
      )
      .then(response => {
        // TODO need cleanup

        const amount = window.localStorage.getItem('amountToContribute');
        const ticketType = window.localStorage.getItem('ticketType');
        if (ticketType && ticketType !== 'undefined') {
          window.localStorage.removeItem('ticketType');
        }

        if (amount && amount !== 'undefined') {
          window.localStorage.removeItem('amountToContribute');
          // store contributedToUdenEvent for redirect after payment-return
          window.localStorage.setItem(
            'contributedToUdenEvent',
            JSON.stringify(1)
          );
        }
        dispatch({ type: 'IDEAL_CONTRIBUTE_WITHOUT_ACCOUNT_SUCCESS' });
        return response.data;
      });
  };
}

export function fetchCardPreRegistrationDataAction() {
  // get preregistration data to send to tokenization server
  return dispatch => {
    return eventsService
      .fetchCardPreregistrationDataService()
      .then(response => {
        dispatch({ type: 'FETCH_CARD_PREREGISTRATION_DATA_SUCCESS' });
        return response.data;
      })
      .catch(err => {
        console.log(' ERROR FETCHING PREREGISTRATION DATA', err);
      });
  };
}

export function fetchCardPreRegistrationDataGuestAction(
  personalData
) {
  // get preregistration data to send to tokenization server
  return dispatch => {
    return eventsService
      .fetchCardPreRegistrationDataGuestService(personalData)
      .then(response => {
        dispatch({ type: 'FETCH_CARD_PREREGISTRATION_DATA_SUCCESS' });
        return response.data;
      })
      .catch(err => {
        console.log(' ERROR FETCHING PREREGISTRATION DATA', err);
      });
  };
}

export function postCardDetailsToTokenizationServerAction(
  cardDetails,
  preRegistrationDataResponse
) {
  // send to tokenization server and receive registration data string
  return dispatch => {
    return eventsService
      .postCardDetailsToTokenizationServerService(
        eventsService.getTokenizationServerRequestData(
          cardDetails,
          preRegistrationDataResponse
        ),
        preRegistrationDataResponse.cardRegistrationUrl
      )
      .then(response => {
        dispatch({ type: 'POST_CARD_PRE_REGISTRATION_SUCCESS' });

        return response;
      })
      .catch(err => {
        console.log(' ERROR POSTING PREREGISTRATION DATA', err);
      });
  };
}

export function contributeWithAccountAndSavedCard(
  data,
  id,
  currency,
  cardRegistrationId
) {
  return dispatch => {
    return eventsService
      .contributeWithSavedCard(
        eventsService.getPaymentDataWithSavedCard(data, currency, cardRegistrationId),
        id
      )
      .then(response => {
        dispatch({ type: 'CONTRIBUTE_WITH_ACCOUNT_SUCCESS' });
        // TODO need cleanup
        const amount = window.localStorage.getItem('amountToContribute');
        const ticketType = window.localStorage.getItem('ticketType');
        if (ticketType && ticketType !== 'undefined') {
          window.localStorage.removeItem('ticketType');
        }
        if (amount && amount !== 'undefined') {
          window.localStorage.removeItem('amountToContribute');
          // store contributedToUdenEvent for redirect after payment-return
          window.localStorage.setItem(
            'contributedToUdenEvent',
            JSON.stringify(1)
          );
        }
        return response.data;
      });
  };
}

export function contributeWithAccount(
  data,
  uuId,
  currency,
  registrationData,
  cardRegistrationId,
) {
  return dispatch => {
    return eventsService
      .contribute(
        eventsService.getPaymentData(
          data,
          currency,
          registrationData,
          cardRegistrationId
        ),
        uuId
      )
      .then(response => {
        dispatch({ type: 'CONTRIBUTE_WITH_ACCOUNT_SUCCESS' });
        // TODO need cleanup
        const amount = window.localStorage.getItem('amountToContribute');
        const ticketType = window.localStorage.getItem('ticketType');
        if (ticketType && ticketType !== 'undefined') {
          window.localStorage.removeItem('ticketType');
        }
        if (amount && amount !== 'undefined') {
          window.localStorage.removeItem('amountToContribute');
          // store contributedToUdenEvent for redirect after payment-return
          window.localStorage.setItem(
            'contributedToUdenEvent',
            JSON.stringify(1)
          );
        }
        return response.data;
      });
  };
}

export function contributeWithoutAccount(
  data,
  uuid,
  currency,
  registrationData,
  cardRegistrationId
) {
  return dispatch => {
    return eventsService
      .contributeWithoutAccount(
        eventsService.getPaymentData(
          data,
          currency,
          registrationData,
          cardRegistrationId
        ),
        uuid
      )
      .then(response => {
        dispatch({ type: 'CONTRIBUTE_WITHOUT_ACCOUNT_SUCCESS' });
        // TODO need cleanup
        const amount = window.localStorage.getItem('amountToContribute');
        const ticketType = window.localStorage.getItem('ticketType');
        if (ticketType && ticketType !== 'undefined') {
          window.localStorage.removeItem('ticketType');
        }
        if (amount && amount !== 'undefined') {
          window.localStorage.removeItem('amountToContribute');
          // store contributedToUdenEvent for redirect after payment-return
          window.localStorage.setItem(
            'contributedToUdenEvent',
            JSON.stringify(1)
          );
        }
        return response.data;
      });
  };
}

export function deleteCreditCard() {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .deleteCreditCard()
      .then(response => {
        dispatch(deleteCreditCardSuccess());
      })
      .catch(e => {
        dispatch(deleteCreditCardFail());
      });
  };
}

export function fetchCreditCardData() {
  return dispatch => {
    return eventsService
      .getCreditCardData()
      .then(result => {
        dispatch(fetchCreditCardDataSucess(result.data));
      })
      .catch(e => {
        dispatch(fetchCreditCardDataFail());
      });
  };
}

export function deleteDebitCard() {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .deleteDebitCard()
      .then(response => {
        dispatch(deleteDebitCardSuccess());
      })
      .catch(e => {
        dispatch(deleteDebitCardFail());
      });
  };
}

export function fetchDebitCardData() {
  return dispatch => {
    return eventsService
      .getDebitCardData()
      .then(result => {
        dispatch(fetchDebitCardDataSucess(result.data));
      })
      .catch(e => {
        dispatch(fetchDebitCardDataFail());
      });
  };
}

export function confirmCreditCardWithAccount(eventId, transactionId) {
  return dispatch => {
    return eventsService.confirmCreditCardWithAccount(eventId, transactionId);
  };
}

export function confirmCreditCardWithoutAccount(
  userId,
  eventUuid,
  transactionId
) {
  return dispatch => {
    return eventsService.confirmCreditCardWithoutAccount(
      userId,
      eventUuid,
      transactionId
    );
  };
}

export function cashOutEmail(eventUuId, cashOutData) {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .cashOutEmail(eventUuId, cashOutData)
      .then(() => {
        clearEventsListAndDetails(dispatch);
        dispatch({ type: 'CASHOUT_EMAIL_SUCCESS' });
      })
      .catch(() => {
        dispatch(errorCall());
      });
  };
}

export function cashOutBank(eventUuId, profileData, cardData, amount, client) {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .cashOutBank(
        eventUuId,
        eventsService.getCashOutData(profileData, cardData, amount, client)
      )
      .then(() => {
        clearEventsListAndDetails(dispatch);
        dispatch({ type: 'CASHOUT_BANK_SUCCESS' });
      })
      .catch(e => {
        dispatch(errorCall());
        throw e;
      });
  };
}

export function cashOutDebit(eventUuId, profileData, cardData, amount) {
  return dispatch => {
    dispatch(beginCall());
    return eventsService
      .cashOutDebit(
        eventUuId,
        eventsService.getDebitCashOutData(profileData, cardData, amount)
      )
      .then(() => {
        clearEventsListAndDetails(dispatch);
        dispatch({ type: 'CASHOUT_DEBIT_SUCCESS' });
      })
      .catch(e => {
        dispatch(errorCall());
        throw e;
      });
  };
}

export function clearEventsList() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      resolve(dispatch(clearList()));
    });
  };
}

export function fetchRequestWishlist(eventId) {
  return dispatch => {
    return eventsService
      .getRequestWishlist(eventId)
      .then(invitation => dispatch(requestedWLSuccess(invitation.data)));
  };
}

export function fetchEventWLGoals(eventId) {
  return dispatch => {
    return eventsService
      .getEventWLGoals(eventId)
      .then(goals => dispatch(eventWLGoalsSuccess(goals.data)));
  };
}

export const getRequestWishlistStatus = event => dispatch =>
  eventsService.getRequestWishlistStatus(event).then(status => {
    dispatch(requestWishlistStatus(status));
  });

export function fetchEventPermissionsByUuid(uuid) {
  return dispatch => {
    return eventsService
      .getEventPermissionsByUuid(uuid)
      .then(response => {
        dispatch(eventDetailsSuccess(response.data));
      })
      .catch(e => {
        dispatch(eventFail());
        throw e;
      });
  };
}
