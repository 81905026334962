import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import i18n from '../../../i18n';
import Button from '../../../components/Button';
import LanguageSelector from '../../../components/LanguageSelector';
import * as constants from '../../../services/constants';

const region = constants.REGION;

const Navigation = ({ t }) => {
  return (
    <nav className="header__navigation">
      <ul className="header__navigation-list">
        <li className="header__navigation-list-item">
          <Link ta-id="header_about" to="/about">
            {t('home.header.about-us')}
          </Link>
        </li>
        <li className="header__navigation-list-item">
          <Link to="/sign-up" ta-id="header_create-account">
            {t('home.header.create-account')}
          </Link>
        </li>
        <li className="header__navigation-list-item">
          <Link to="/login">
            <Button
              testSelector="header_login"
              className="button button--primary button--login"
            >
              {t('home.header.login')}
            </Button>
          </Link>
        </li>
      </ul>
      {region === 'EU' && <LanguageSelector language={i18n.language} />}
    </nav>
  );
};

export default translate()(Navigation);
